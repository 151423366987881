import {useQuery} from "@tanstack/react-query"
import axios from "axios"

const getData = async () => {
    const response = await axios.get(`${process.env.REACT_APP_PUBLIC_API}/api/global-settings`);
    return response.data;
}

export const useSettings = () => {
    return useQuery({
        queryKey : ['getGlobalSettings'],
        queryFn : getData,
        refetchOnWindowFocus : false,
    })
}