import { useQuery } from "@tanstack/react-query";
import axios from "axios"
import { checkErrors } from "../global/global-functions/checkRequestErrors";
const cartApi = `${process.env.REACT_APP_PUBLIC_API}/api/carts`;
const getCartData = async() => {
    const token = window.localStorage.getItem("token");
    const config = {
        headers : {
            Authorization : `Bearer ${token}`
        }
    }
    try {
        const response = await axios.get(cartApi , config);
        return response.data
    }catch (error) {
        checkErrors(error.response , error.message);
    }
}

export const useCart = () => {
    const user = window.localStorage.getItem("user")
    return useQuery({
        queryKey : ['getCartQuery'],
        queryFn : getCartData,
        refetchOnWindowFocus : false,
        enabled : !!user,
    })
}