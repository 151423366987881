import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { checkErrors } from "../global/global-functions/checkRequestErrors";

const wishlistApi = `${process.env.REACT_APP_PUBLIC_API}/api/wishlists`;
const getWishlistData = async () => {
    const token = window.localStorage.getItem("token")
    const config = {
        headers : {
            Authorization : `Bearer ${token}`
        }
    }
    try {
        const response = await axios.get(wishlistApi , config);
        return response.data
    }catch (error) {
        checkErrors(error.response , error.message);
    }
}

export const useWishlist = () => {
    const user = window.localStorage.getItem("user");
    return useQuery({
        queryKey : ['getWishlistData'],
        queryFn : getWishlistData,
        refetchOnWindowFocus : false,
        enabled : !!user
    })
}